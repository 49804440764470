<template>
  <div>
    <DefaultHeader />

    <div class="main-content">
      <b-container>
        <PreviewBlock />
        <RouterView />
      </b-container>
    </div>
  </div>
</template>

<script>
import DefaultHeader from '@/Modules/Base/Components/DefaultHeader.vue'
import PreviewBlock from '@/Modules/Base/Components/PreviewBlock.vue'

export default {
  name: 'DefaultApp',

  components: { DefaultHeader, PreviewBlock },

  mounted() {
    document.body.classList.add('broker-portal')
  },
}
</script>
